import React from 'react'

export const AddressButton = () => (
  <section className="check-your-address">
    <div className="container">
      <a href="/sign-up" className="btn btn-xl btn-block btn-accord-outline">
        Check your address here
      </a>
    </div>
  </section>
)
