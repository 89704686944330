import React, { useEffect } from 'react'

import helper from '../services/helper'
import { Layout } from '../layouts/Layout'
import {
  HeroBanner,
  ShowcaseProducts,
  Showcase,
  SEO,
} from '../components/shared'
import { AddressButton } from '../components/nbn/'
import { PlanSuggestion } from '../components/shared/plan-suggestion'

const Index = () => {
  useEffect(() => {
    if (window.innerWidth > 991) {
      helper.syncHeights('.showcase-why-accord .showcase-item h3')
    }
  }, [])

  return (
    <Layout>
      <SEO
        title="Commtel"
        description="Connect your world with Broadband that never sleeps!"
      />

      <main id="page-product-nbn">
        <HeroBanner />
        <AddressButton />
        <PlanSuggestion pageType="nbn" />
        <Showcase />
      </main>
    </Layout>
  )
}

export default Index
