import React from 'react'
import { Link } from 'gatsby'
import { Plan } from '../Plan'

export const PlanSuggestion = ({ pageType }) => <ContainerContent />

const ContainerContent = () => {
  const sharedContractPlansProps = {
    banner: true,
    cisUrl: '/pdf/sales-critical-information-summary-nbn.pdf',
    optionalPlanCostCopy: '',
  }

  const contractPlans = [
    {
      ...sharedContractPlansProps,
      id: 'CTLNBN25/10-0M',
      name: 'Commtel<br />Basic',
      blurb: 'Basic',
      price: '69.99',
      minimumTotalCost: '69.99',
      typicalEveningSpeeds: 'Typical Evening Download/Upload Speed 22/8.5 Mbps',
    },
    {
      ...sharedContractPlansProps,
      id: 'CTLNBN50/20-0M',
      name: 'Commtel<br />Standard',
      blurb: 'Standard',
      price: '74.99',
      minimumTotalCost: '74.99',
      typicalEveningSpeeds: 'Typical Evening Download/Upload Speed 48/17 Mbps',
    },
    {
      ...sharedContractPlansProps,
      id: 'CTLNBN100/20-0M',
      name: 'Commtel<br />Fast<br />',
      blurb: 'Fast',
      price: '89.99',
      minimumTotalCost: '89.99',
      typicalEveningSpeeds: 'Typical Evening Download/Upload Speed 95/17 Mbps',
    },
    {
      ...sharedContractPlansProps,
      id: 'CTLNBN250/25-0M',
      name: 'Commtel<br />Super Fast<br />',
      blurb: 'Super Fast',
      price: '109.99',
      minimumTotalCost: '109.99',
      typicalEveningSpeeds: 'Typical Evening Download/Upload Speed 240/21 Mbps',
    },
    {
      ...sharedContractPlansProps,
      id: 'CTLNBN1000/50-0M',
      name: 'Commtel<br />Ultra Fast<br />',
      blurb: 'Ultra Fast',
      price: '139.99',
      minimumTotalCost: '139.99',
      typicalEveningSpeeds: 'Typical Evening Download/Upload Speed 500/42 Mbps',
    },
  ]

  return (
    <div className="showcase-plans" id="plans">
      <div>
        <h2 className="banner-title text-white">
          Choose the plan that's right for you
        </h2>
        <section className="plan-table-new">
          {contractPlans.reduce(
            (acc, planProps) => [
              ...acc,
              <Plan key={planProps.blurb} {...planProps} />,
            ],
            []
          )}
        </section>
      </div>
    </div>
  )
}
